import React, { createContext, useEffect,useState } from 'react';

import { ITableProviderProps, useTable } from '@funfarm/kit';

import { tourneyFilterStorage } from 'helpers';
import { IFilterParams } from 'types';


export interface IProviderProps extends Omit<ITableProviderProps, 'tableRef'> {
    filterParams?: IFilterParams,
    setFilterParams?: (filterParams: IFilterParams) => void,
}

export const defaultFilterParams = {
    date_start: new Date().getTime(),
    date_end: new Date().getTime() + 43200 * 1000,
    bi_start: '',
    rooms_id: [],
    tourney_type: [],
    show_hidden: false,
    show_passed: false,
    is_series: ''
};

const defaultContextValue = {
    filterParams: defaultFilterParams
};



const TourContext = createContext<IProviderProps>(defaultContextValue);

const TourProvider = (props: IProviderProps) => {
    const {} = props;
    const { tableColumns, setTableColumns } = useTable();
    const [filterParams, setFilterParamsState] = useState<IFilterParams>(defaultFilterParams);


    const setFilterParams = (filterParams: IFilterParams) => {
        if(filterParams) {
            const storageParams = { ...filterParams };
            delete storageParams.date_start
            delete storageParams.date_end

            tourneyFilterStorage.setItem(JSON.stringify(storageParams));
        }

        setFilterParamsState(filterParams);
    };

    useEffect(() => {
        const stFilterParams = tourneyFilterStorage.getItem();

        if(stFilterParams)
            setFilterParams({
                ...JSON.parse(stFilterParams),
                date_start: filterParams.date_start,
                date_end: filterParams.date_end
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <TourContext.Provider value={{
            ...defaultContextValue,
            tableColumns, setTableColumns,
            filterParams, setFilterParams,
        }}>
            {props.children}
        </TourContext.Provider>
    );
};


export {TourProvider, TourContext};
