import { differenceInCalendarDays, format, isAfter, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

import i18n, { localeMap } from 'helpers/i18n';

export const DATE_TIME_FORMAT = 'dd MMM HH:mm';

export const toApiDateString = (inputDate?: Date): string => {
    const date = inputDate || new Date(); // Use the provided date or fallback to the current date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const timestampToDate = (
    input: number,
    dateFormat = DATE_TIME_FORMAT,
) =>
    format(new Date(input * 1000), dateFormat);

export const formatDateString = (
    input: string,
    dateFormat = DATE_TIME_FORMAT,
) =>
    format(new Date(input), dateFormat);

export const formatMonth = (date: string) => {
    return format(parseISO(date), 'LLL', { locale: localeMap[i18n.language] })
}
export const formatPeriod = (
    date_start: string,
    date_end: string | null
): string => {
    const start = parseISO(date_start);
    if (!date_end) return format(start, 'dd.MM.yyyy') + " - ...";
    const end = parseISO(date_end);

    // Check if start and end are in the same year
    const sameYear = start.getFullYear() === end.getFullYear();

    // Format with or without the year
    const startFormat = sameYear ? 'dd MMM' : 'dd.MM.yyyy';
    const endFormat = sameYear ? 'dd MMM' : 'dd.MM.yyyy';

    const result = `${format(start, startFormat, { locale: ru })} - ${format(end, endFormat, { locale: ru })}`;
    return sameYear ? result.replaceAll(".", "") : result;
};

export const durationInDays = (
    date_start: string,
    date_end: string | null,
): number => {
    const start = parseISO(date_start);
    const end = date_end ? parseISO(date_end) : new Date();

    return differenceInCalendarDays(end, start);
};

export const isFutureDate = (date: string): boolean => {
    const parsedDate = parseISO(date);
    const now = new Date();
    return isAfter(parsedDate, now);
};
