import React from "react";

import { Button } from "@funfarm/kit";
import IconOn from "@funfarm/kit/Icon/icons/On";

import { money } from "helpers/numbers";
import { BalanceHistoryItem } from "types/finances";

import css from "../finances.module.scss";

const prepareBalanceHistoryColumns = (
    roomNames: Array<{
    id: number;
    title: string;
  }>
) => ([
    {
        header: "Дата",
        fieldName: "date",
        colspan: 1
    },
    {
        header: "Профит",
        fieldName: "profit",
        colspan: 1,
        renderCell: (item: BalanceHistoryItem) => (
            <>
                {money(item.profit, "USD")}
                {item.editable && (
                    <Button
                        view="light"
                        color="secondary"
                        size="small"
                        icon={
                            <IconOn
                                size="large"
                                className={Math.random() > 0.5 ? css.red : css.green}
                            />
                        }
                    />
                )}
            </>
        )
    },
    ...(roomNames.map((room) => ({
        header: room.title,
        fieldName: `room-${room.id}`,
        colspan: 4,
        renderCell: ({ room_balances }: BalanceHistoryItem) => {
            const roomBalance = room_balances.find(({ room_id }) => room_id === room.id);
            if (!roomBalance) return <>-</>;
            return (
                <>
                    {/*JSON.stringify(room_balances)*/}
                    {money(roomBalance.balance_usd, "USD")}
                    {(roomBalance.allow_to_deactivate || roomBalance.allow_to_activate) && (
                        <Button
                            view="light"
                            color="secondary"
                            size="small"
                            icon={
                                <IconOn
                                    size="large"
                                    className={
                                        roomBalance.allow_to_deactivate ? css.red : css.green
                                    }
                                />
                            }
                        />
                    )}
                </>
            );
        }
    }))),
    {
        header: "Итого",
        fieldName: "total",
        colspan: 1,
        renderCell: (item: BalanceHistoryItem) => money(item.total, "USD")
    }
]);

export default prepareBalanceHistoryColumns;
