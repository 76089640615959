import React, { HTMLAttributes, useRef } from 'react';
import classNames from 'classnames';

import { ESizes, ETableView } from '@funfarm/kit/types';

import { CurrentFilters } from './ActionFilter/CurrentFilters';
import { ActionCheckbox } from './ActionCheckbox';
import { ActionFilter } from './ActionFilter';
import { ActionSort } from './ActionSort';
import { Separator } from './Separator';
import { Settings } from './Settings';
import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';
import { ITableProviderProps, TableProvider } from './TableProvider';
import { TableRow } from './TableRow';

import css from './table.module.scss';


export type TColumn = {
    name: string,
    label: string,
    default?: boolean,
    colspan?: number,
}


interface Props extends HTMLAttributes<HTMLDivElement>, Omit<ITableProviderProps, 'tableRef'> {
    cells?: number, // max 24
    nowrap?: boolean,
    fontSize?: keyof typeof ESizes,
    align?: string,
    valign?: string,
    overflow?: boolean,
    view?: keyof typeof ETableView,
}

/** @deprecated */
const Table = (props: Props) => {
    const tableRef = useRef(null);

    const {
        cells, nowrap, align, valign = 'center', fontSize, overflow, view,
        className, style,
        ...providerProps
    } = props;


    return (
        <TableProvider {...{ tableRef, ...providerProps }}>
            <div className={classNames(css.wrapper, overflow && css.overflow)}>
                <div
                    ref={tableRef}
                    className={classNames(
                        css.table,
                        (cells && css[`cells${cells}`]),
                        (align && css[`align-${align}`]),
                        (valign && css[`valign-${valign}`]),
                        (fontSize && css[`font-${fontSize}`]),
                        (nowrap && css.nowrap),
                        (view && css[`view-${view}`]),
                        className
                    )}
                    style={style}>
                    {props.children}
                </div>
            </div>
        </TableProvider>
    );
};


Table.Row = TableRow;
Table.Cell = TableCell;
Table.Header = TableHeader;
Table.ActionSort = ActionSort;
Table.ActionFilter = ActionFilter;
Table.CurrentFilters = CurrentFilters;
Table.ActionCheckbox = ActionCheckbox;
Table.Settings = Settings;
Table.Separator = Separator;

export { Table };
