import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    IconFilterColumn,
    IconHelpCircle,
    Switcher,
    tableStorage,
    TColumn,
    useOnClickOutside,
} from '@funfarm/kit';

import { Helper } from 'components/Helper';
import { TourContext } from 'components/Tournaments/TourProvider';

import css from './settings.module.scss';


export const TableColumnSettings = () => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const tableName = 'tournaments';

    const { tableColumns, setTableColumns } = useContext(TourContext);
    const [open, setOpen] = useState<boolean>(false);

    useOnClickOutside(containerRef, async () => setOpen(false));


    const tournamentTableColumns = useMemo(() => (
        [
            { label: t('Ранг'), name: 'rank' },
            { label: t('Дата завершения'), name: 'end_date', default: true },
            { label: t('Тип'), name: 'type', default: true },
            // { label: t('Винрейт'), name: 'vinrate' },
            // { label: t('Фиши'), name: 'fishes' },
            { label: t('О турнире'), name: 'tournament' },
        ]), [t])


    useEffect(() => {
        setTableColumns && setTableColumns(prevColumns => {
            const tableSettingsString = tableStorage.getItem();
            const tableSettings = tableSettingsString ? JSON.parse(tableSettingsString) : {};
            const defaultColumns = tableSettings[tableName] ?? tournamentTableColumns.filter(col => col.default).map(col => col.name);

            const uniqueColumns = new Set([ ...prevColumns, ...defaultColumns ]);

            tableStorage.setItem(JSON.stringify({ ...tableSettings, [tableName]: [ ...uniqueColumns ] }));

            return [ ...uniqueColumns ];
        });
    }, [tournamentTableColumns, setTableColumns, tableName]);


    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;

        setTableColumns && setTableColumns(prevColumns => {
            const tableSettingsString = tableStorage.getItem();
            const tableSettings = tableSettingsString ? JSON.parse(tableSettingsString) : {};
            const checkedColumns = checked ? [ ...prevColumns, value ] : prevColumns.filter(col => col !== value);

            tableStorage.setItem(JSON.stringify({ ...tableSettings, [tableName]: checkedColumns }));

            return checkedColumns;
        });
    };


    const setDefault = () => {
        setTableColumns && setTableColumns(() => {
            const checkedColumns = tournamentTableColumns
                .filter(col => col.default)
                .map(col => col.name);

            const tableSettingsString = tableStorage.getItem();
            const tableSettings = tableSettingsString ? JSON.parse(tableSettingsString) : {};

            tableStorage.setItem(JSON.stringify({ ...tableSettings, [tableName]: checkedColumns }));

            return checkedColumns;
        });
    };


    return (
        <>
            <Helper
                path="tourneys"
                buttonProps={{
                    label: "Инструкция",
                    iconLeft: <IconHelpCircle/>
                }}
            />
            <Button
                iconLeft={<IconFilterColumn size="large" />}
                label={t('Setup columns')}
                onClick={() => setOpen(true)}
            />
            {
                open &&
                <div className={css.settings} ref={containerRef}>
                    <p className={css.settings__desc}>Настройка столбцов таблицы</p>
                    <ul className={css.settings__list}>
                        {
                            tournamentTableColumns.map((item: TColumn) => {
                                return (
                                    <li key={item.name} className={css.settings__item}>
                                        <span className={css.settings__text}>{item.label}</span>
                                        <Switcher
                                            value={item.name}
                                            checked={tableColumns ? tableColumns.includes(item.name) : item.default}
                                            onChange={onChange}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <span className={css.settings__default}>
                        <Button view="text" color="secondary" onClick={setDefault}>Настроить по умолчанию</Button>
                    </span>
                </div>
            }
        </>
    )
}
