import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconCopy = (props: IconProps) => {
    return <Icon  id="IconCopy" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3C7.22385 3 7 3.22385 7 3.5V5H16.5C17.8807 5 19 6.11929 19 7.5V17H20.5C20.7762 17 21 16.7762 21 16.5V3.5C21 3.22385 20.7762 3 20.5 3H7.5ZM19 19H20.5C21.8807 19 23 17.8807 23 16.5V3.5C23 2.1193 21.8807 1 20.5 1H7.5C6.11925 1 5 2.1193 5 3.5V5H3.5C2.11929 5 1 6.11929 1 7.5V20.5C1 21.8807 2.11929 23 3.5 23H16.5C17.8807 23 19 21.8807 19 20.5V19ZM3.5 7C3.22386 7 3 7.22386 3 7.5V20.5C3 20.7761 3.22386 21 3.5 21H16.5C16.7761 21 17 20.7761 17 20.5V7.5C17 7.22386 16.7761 7 16.5 7H3.5Z" />
    </Icon>;
};

export default IconCopy;
