import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    AllCommunityModule,
    ColDef,
    colorSchemeDark,
    ModuleRegistry,
    themeQuartz,
    ValueFormatterParams, ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';

import css from '@funfarm/lk/src/components/Finances/finances.module.scss';
import { timestampToDate } from '@funfarm/lk/src/helpers';
import { money, symbolToCurrencyCode } from '@funfarm/lk/src/helpers/numbers';
import usePagination from '@funfarm/lk/src/hooks/usePagination';
import { ApiFetchFunction, FilterComponent, FilterPrototype, TableItemPrototype } from '@funfarm/lk/src/types/table';

import { EColors } from '../types';
import { RowClassParams } from 'ag-grid-community/dist/types/src/entities/gridOptions';

import { Badge } from '../Badge';
import CopyToClipboard from '../CopyToClickboard/CopyToClockboard';
import Pagination from '../Pagination/Pagination';

const darkTheme = themeQuartz
    .withPart(colorSchemeDark)
    .withParams({
        backgroundColor: "#1C1E23",
        foregroundColor: "#7A7F89",
        headerTextColor: "#ACAFB5",

        spacing: 12,

        wrapperBorder: false,
        headerRowBorder: false,
        // rowBorder: "#121315",
    });

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

type DataGridProps<
    ItemType extends TableItemPrototype,
    FilterType extends FilterPrototype,
> = {
    fetchData: ApiFetchFunction<ItemType, FilterType>,
    fetchKey: string,
    columns: ColDef<ItemType>[],
    getRowClass?: (params: RowClassParams<ItemType>) => string,
    pagination?: boolean,
    filterComponent?: FilterComponent<FilterType>
};

const DataGrid = <
    ItemType extends TableItemPrototype,
    FilterType extends FilterPrototype,
> ({
        fetchData,
        fetchKey,
        columns,
        getRowClass,
        pagination = true,
        filterComponent,
    }: DataGridProps<ItemType, FilterType>) => {
    
    const [filter, setFilter] = useState<FilterType>();
    const { page, setPage, pageSize } = usePagination();

    const { data, isLoading } = useQuery({
        queryKey: [fetchKey, filter, page, pageSize],
        queryFn: () => fetchData(
            filter,
            (page - 1) * pageSize,
            pageSize + 1
        )
    });
    

    // If we got more than pageSize, there's a next page
    const hasNextPage = useMemo(
        () => !!data?.length && !isLoading && data?.length > pageSize,
        [data?.length, isLoading, pageSize]
    );
    // Trim extra item before passing to grid
    const trimmedData = useMemo(
        () => data?.slice(0, pageSize),
        [data, pageSize]
    );

    const [columnDefs] = useState(columns);

    const FilterElement = filterComponent;

    return (
        <>
            {FilterElement && (    
                <FilterElement values={filter} setValues={setFilter}/>
            )}
            <AgGridReact
                loading={isLoading}
                rowData={trimmedData}
                columnDefs={columnDefs}
                columnTypes={{
                    date: {
                        valueFormatter: (p: ValueFormatterParams) =>
                            timestampToDate(p.value),
                    },
                    amount: {
                        valueGetter: (p: ValueGetterParams) =>
                            !p.data.amount ? "-" : money(
                                Number(p.data.amount),
                                symbolToCurrencyCode(p.data.currency),
                            ),
                    },
                    highlightAmount: {
                        cellRenderer: (p: ValueFormatterParams) => (
                            <span
                                className={
                                    Number(p.value) > 0 ? css.green : css.red
                                }
                            >
                                {Number(p.value) > 0 && '+ '}
                                {money(
                                    Number(p.value),
                                    symbolToCurrencyCode(p.data.currency),
                                )}
                            </span>
                        ),
                    },
                    amountCurrencyObject: {
                        valueGetter: (p: ValueGetterParams) =>
                            money(
                                p.data.amount,
                                symbolToCurrencyCode(p.data.currency.abrname),
                            ),
                    },
                    roomObject: {
                        valueFormatter: (p: ValueFormatterParams) =>
                            p.value.title,
                    },
                    accountLogo: {
                        cellRenderer: (p: ValueFormatterParams) => {
                            for (const title in accountLogos) {
                                if (p.value.toLowerCase().includes(title))
                                    return (
                                        <img
                                            src={accountLogos[title]}
                                            alt={title}
                                            style={{
                                                maxHeight: '18px',
                                                maxWidth: '72px',
                                            }}
                                        />
                                    );
                            }
                            return p.value;
                        },
                    },
                    badge: {
                        cellRenderer: (p: ValueFormatterParams) => (
                            <div style={{ overflow: "hidden" }}>
                                <Badge
                                    size="small"
                                    color={p.colDef.refData![p.value] as keyof typeof EColors || "default"}
                                >
                                    {p.colDef.refData!.translationPrefix
                                        ? t(p.colDef.refData!.translationPrefix + p.data.package_status)
                                        : p.value}
                                </Badge>
                            </div>
                        ),
                    },
                    copiable: {
                        cellRenderer: useMemo(() => (p: ValueFormatterParams) => (
                            <CopyToClipboard>
                                {p.value}
                            </CopyToClipboard>
                        ), []),
                    }
                }}
                suppressDragLeaveHidesColumns
                theme={darkTheme}
                getRowClass={getRowClass}
            />
            {pagination && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    loading={isLoading}
                    hasNextPage={hasNextPage}
                />
            )}
        </>
    );
};

const accountLogos: Record<string, string> = {
    "skrill": "/logo/skrill.png",
    "luxon": "/logo/luxon.png",
};

export default DataGrid;