import React, { PropsWithChildren } from 'react';

import { IconCopy } from '../Icon';
import { Toast } from '../Toast';

const CopyToClipboard: React.FC<PropsWithChildren> = ({ children }) => (
    <div
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            const text = event.currentTarget.innerText;
            navigator.clipboard.writeText(text).then(() => {
                Toast.success({ message: 'Copied!' });
            });
        }}
        style={{
            display: "flex",
            // alignItems: "center",
            gap: "4px",
        }}
    >
        {children}
        <IconCopy />
    </div>
);

export default CopyToClipboard;
