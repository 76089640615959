import { API } from 'helpers/apiInstance';
import { preparePeriod } from 'helpers/table';
import {
    BalanceHistoryItem,
    BalanceHistoryResponse,
    BalancesResponse,
    CalculationConditionsResponse,
    CrossRatesResponse,
    CurrentCalculationResponse,
    FinanceCalculation,
    FinanceCalculationResponse,
    FinanceMetricsProps,
    FinanceTransaction,
    FinanceTransactionsResponse,
    OperationTypesResponse,
    RoomsResponse,
    UsedRoomsResponse,
    Withdrawal,
    WithdrawalRequest, WithdrawalRequestsFilter,
    WithdrawalRequestsResponse,
    WithdrawalsFilter,
    WithdrawalsResponse,
} from 'types/finances';
import { ApiFetchFunction } from 'types/table';

import { DEFAULT_PAGE_SIZE } from '../hooks/usePagination';

export const getWithdrawals: ApiFetchFunction<Withdrawal, WithdrawalsFilter> = (
    filter,
    page?: number,
    take?: number,
) =>
    API.get<any, WithdrawalsResponse>(
        '/check/finances/withdraws',
        { params: {
            ...preparePeriod(filter || {}),
            page,
            take,
        }}
    ).then(
        (response) => response.withdraws
    );

export const getCalculations: ApiFetchFunction<FinanceCalculation, any> = () =>
    API.get<any, FinanceCalculationResponse>(
        '/check/calculations'
    ).then(
        (response) => response.calculations
    );

export const getBalancesHistory: ApiFetchFunction<BalanceHistoryItem, any> = () =>
    API.get<any, BalanceHistoryResponse>(
        '/check/balances/history'
    ).then(
        (response) => response.balances,
    );

export const getTransactions: ApiFetchFunction<FinanceTransaction, any> = (
    filter,
    skip = 0,
    take = DEFAULT_PAGE_SIZE,
) =>
    API.get<any, FinanceTransactionsResponse>(
        '/check/finances/transactions',
        { params: {
            ...preparePeriod(filter || {}),
            skip,
            take,
        }},
    ).then(
        (response) => response.transactions
    );

export const getWithdrawalRequests: ApiFetchFunction<WithdrawalRequest, WithdrawalRequestsFilter> = (
    filter,
    skip = 0,
    take = DEFAULT_PAGE_SIZE,
) =>
    API.get<any, WithdrawalRequestsResponse>(
        '/check/requests',
        { params: {
            ...filter,
            skip,
            take,
        }}
    ).then(
        (response) => response.requests
    );

export const getFinanceMetrics = () =>
    API.get<any, {
        data: FinanceMetricsProps,
    }>('/check/finances/brm');

export const getBalances = () =>
    API.get<any, BalancesResponse>('/check/balances');

export const changeBalance = (
    room_id: number,
    amount: number | string,
) =>
    API.put('/check/balances', {
        room_id,
        amount,
    });

export const addBalance = (room_id: number) =>
    API.post("/check/balances/on-way?", { room_id });

export const deleteBalance = (room_id: number) =>
    API.delete(`/check/balances/on-way?room_id=${room_id}`);

export const openNewPackage = (
    condition_id: number,
) =>
    API.post<any, {
        calculations: FinanceCalculation[],
    }>('/check/calculations', {
        condition_id,
    });

export const getCurrentCalculation = () =>
    API.get<any, CurrentCalculationResponse>(
        '/check/calculations/current'
    );

export const getCalculationConditions = () =>
    API.get<any, CalculationConditionsResponse>(
        '/check/calculations/available-conditions'
    );

export const getCrossRates = () =>
    API.get<any, CrossRatesResponse>('/check/library/currencies');

export const getRooms = () =>
    API.get<any, RoomsResponse>(
        '/check/library/rooms'
    );

export const getWithdrawalTypes = () =>
    API.get<any, OperationTypesResponse>(
        '/check/library/withdrawal-types'
    );

export const getUsedRooms = () =>
    API.get<any, UsedRoomsResponse>(
        '/check/balances/used-rooms'
    );

export const createRequest = (
    room_id: number,
    amount: number,
    comment?: string,
) =>
    API.post<any, any>('/check/requests', {
        room_id,
        amount,
        comment,
    });

export const createWithdrawalRequest = (
    room_id: number,
    amount: number,
    type_id: number,
    comment?: string,
) =>
    API.post<any, any>('/check/finances/withdraws', {
        room_id,
        amount,
        comment,
        image_url: "-",
        type_id,
    });

export const createSendMoneyRequest = (
    request_id: number,
    amount: number,
    fee: number,
    comment?: string,
    screenshot_url?: string,
) =>
    API.post<any, any>(`/check/requests/${request_id}/send`, {
        amount,
        fee,
        comment,
        screenshot_url,
    });

export const deleteWithdrawalRequest = (id: number) =>
    API.delete(`/check/requests/${id}`);
