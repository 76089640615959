import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconDelete = (props: IconProps) => {
    return <Icon id="IconDelete" {...props}>
        <path d="M9 9.75C9 9.33579 9.33579 9 9.75 9C10.1642 9 10.5 9.33579 10.5 9.75V15.75C10.5 16.1642 10.1642 16.5 9.75 16.5C9.33579 16.5 9 16.1642 9 15.75V9.75Z" />
        <path d="M14.25 9C13.8358 9 13.5 9.33579 13.5 9.75V15.75C13.5 16.1642 13.8358 16.5 14.25 16.5C14.6642 16.5 15 16.1642 15 15.75V9.75C15 9.33579 14.6642 9 14.25 9Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 5.25V3.75C7.5 2.92157 8.17157 2.25 9 2.25H15C15.8284 2.25 16.5 2.92157 16.5 3.75V5.25H20.25C20.6642 5.25 21 5.58579 21 6C21 6.41421 20.6642 6.75 20.25 6.75H19.3636L18.248 19.0216C18.1076 20.5668 16.812 21.75 15.2604 21.75H8.73964C7.18803 21.75 5.89244 20.5668 5.75196 19.0216L4.63636 6.75H3.75C3.33579 6.75 3 6.41421 3 6C3 5.58579 3.33579 5.25 3.75 5.25H7.5ZM14.85 3.75C14.9328 3.75 15 3.81716 15 3.9V5.25H9V3.9C9 3.81716 9.06716 3.75 9.15 3.75H14.85ZM6.14255 6.75L7.2458 18.8858C7.31604 19.6584 7.96384 20.25 8.73964 20.25H15.2604C16.0362 20.25 16.684 19.6584 16.7542 18.8858L17.8575 6.75H6.14255Z" />
    </Icon>;
};

export default IconDelete;
