import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Button, Dialog, Error, Paper, Row, Skeleton } from '@funfarm/kit';
import CopyToClipboard from '@funfarm/kit/CopyToClickboard/CopyToClockboard';
import IconEdit from '@funfarm/kit/Icon/icons/Edit';

import { money } from 'helpers/numbers';
import { CrossRatesResponse } from 'types/finances';
import { getCrossRates } from 'actions/finances';

import CrossRatesSettings from './CrossRatesSettings';

import css from '../finances.module.scss';


const CrossRatesPanel: FC = () => {
    const defaultCurrencies = ['RUB', 'EUR', 'CNY'];
    const [top, setTop] = useState<string[]>([...defaultCurrencies]);
    const { data, isLoading, isError } = useQuery<CrossRatesResponse>({
        queryKey: ["finances", "crossRates"],
        queryFn: () => getCrossRates()
    });

    const [currencyToChange, setCurrencyToChange] = useState<boolean>(false);
    

    return (
        <Paper>
            <Row>
                <label>Курс к доллару</label> 
                <Button
                    className={css.crossrateEditIcon}
                    view="light"
                    color="secondary"
                    size="large"
                    onClick={() => setCurrencyToChange(true)}
                    icon={
                        <IconEdit
                            size="medium"
                        />
                    }
                />
            </Row>
            <div>
                {isLoading ? (
                    <Skeleton/>
                ) : (isError || !data?.currencies) ? (
                    <Error message="Ошибка получения данных" />
                ) : (
                    <div>
                        {
                            data.currencies
                                .filter(i => i.symbol !=="¤" && i.symbol !== "=")
                                .filter(item => top.includes(item.abrname))
                                .sort((a, b) => {
                                    return top.indexOf(a.abrname) - top.indexOf(b.abrname);
                                })
                                .map((currency) => (
                                    <div className={css.crossrateCurrent} key={currency.id}>
                                        <div>{currency.name}, {currency.symbol}</div>
                                        <div className={css.crossrateCopy}>
                                            <CopyToClipboard>{money(currency.ratio, currency.abrname, 4)}</CopyToClipboard>
                                        </div>
                                        
                                    </div>
                                ))
                        }
                        <Dialog
                            className={css.crossrateDialog}
                            header="Настройки отображения валют за $ 1"
                            open={currencyToChange}
                            onClose={() => setCurrencyToChange(false)}
                        >
                            <CrossRatesSettings
                                allCurrencies={data.currencies}
                                currentCurrencies={top}
                                defaultCurrencies={defaultCurrencies}
                                handleCurrencies={setTop}
                            />
                        </Dialog>
                    </div>
                )}
            </div>
        </Paper>
    );
};

export default CrossRatesPanel;
