import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';

import { Button, Dialog, IconPlus, Toast, useGlobalState } from '@funfarm/kit';
import DataGrid from '@funfarm/kit/DataGrid/DataGrid';
import IconHistory from '@funfarm/kit/Icon/icons/History';

import { money, symbolToCurrencyCode } from 'helpers/numbers';
import { WithdrawalRequest, WithdrawalRequestsFilter } from 'types/finances';
import { IUser } from 'types/index';
import { deleteWithdrawalRequest, getWithdrawalRequests } from 'actions/finances';

import FinanceHeading from 'components/Finances/FinanceHeading';
import css from 'components/Finances/finances.module.scss';
import TransactionsHistory from 'components/Finances/TransactionsHistory/TransactionsHistory';
import CreateRequest from 'components/Finances/Withdrawals/CreateRequest';
import SendMoney from 'components/Finances/Withdrawals/SendMoney';
import WithdrawalsFilter from 'components/Finances/Withdrawals/WithdrawalsFilter';
import { NavigationContext } from 'components/Layout/NavigationProvider';
import PageHeading from 'components/PageHeading/PageHeading';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

const WithdrawalRequests: FC = () => {
    const [newRequestOpened, setNewRequestOpened] = useState(false);
    const [requestDeleting, setRequestDeleting] = useState<number>();
    const [sendMoneyRequest, setSendMoneyRequest] = useState<WithdrawalRequest>();
    const { openDrawer } = useContext(NavigationContext);
    const [currentUser] = useGlobalState<IUser>('user');
    const { refetch } = useBalancesAndBrm();
    const [deleted, setDeleted] = useState<number[]>([]);

    const deleteRequest = useCallback((id: number) => {
        setRequestDeleting(id);
        deleteWithdrawalRequest(id)
            .then(() => {
                setDeleted((prev) => ([
                    ...prev,
                    id,
                ]))
                Toast.success({ message: "Запрос удалён" });
                refetch();
            })
            .finally(() => setRequestDeleting(undefined))
        ;
    }, [refetch]);

    const columnDefs = useMemo<ColDef<WithdrawalRequest>[]>(() => ([
        { field: "datestart", headerName: "Дата", type: "date" },
        { field: "room", headerName: "Счёт", type: "roomObject" },
        { field: "toname", headerName: "Данные счёта", type: "copiable" },
        {
            field: "amountcur",
            headerName: "Сумма",
            valueFormatter: (p: ValueFormatterParams) =>
                p.data.surplus
                    ? "Для излишков"
                    : !p.value
                        ? "-"
                        : money(
                            Number(p.value),
                            symbolToCurrencyCode(p.data.currency),
                        ),
        },
        {
            cellRenderer: (p: ICellRendererParams) => p.data.user.name === currentUser?.username ? (
                <Button
                    view="outlined"
                    size="medium"
                    color="red"
                    className={css.textBtn}
                    label="Удалить"
                    onClick={() => deleteRequest(p.data.id)}
                    loading={p.data.id === requestDeleting}
                />
            ) : (
                <Button
                    view="outlined"
                    size="medium"
                    color="secondary"
                    className={css.textBtn}
                    label={`Отправить ${p.data.currency.abrname}`}
                    onClick={() => setSendMoneyRequest(p.data)}
                />
            ),
        }
    ]), [currentUser?.username, deleteRequest, requestDeleting]);

    return (
        <>
            <FinanceHeading/>
            <PageHeading
                title="Активные запросы"
                tag="h2"
                renderButtons={() => (
                    <>
                        <Button
                            color="default"
                            onClick={() => openDrawer!(<TransactionsHistory/>)}
                            iconLeft={<IconHistory/>}
                        >
                            История переводов
                        </Button>
                        <Button
                            color="secondary"
                            iconLeft={<IconPlus/>}
                            onClick={() => setNewRequestOpened(true)}
                        >
                            Создать новый запрос
                        </Button>
                        {newRequestOpened && (
                            <Dialog
                                header="Создать запрос"
                                open={newRequestOpened}
                                onClose={() => setNewRequestOpened(false)}
                            >
                                <CreateRequest
                                    onClose={() => setNewRequestOpened(false)}
                                />
                            </Dialog>
                        )}
                    </>
                )}
            />
            <div style={{ height: "90%" }}>
                <DataGrid <WithdrawalRequest, WithdrawalRequestsFilter>
                    fetchData={getWithdrawalRequests}
                    fetchKey="finances/requests"
                    columns={columnDefs}
                    filterComponent={WithdrawalsFilter}
                    getRowClass={(p) => p.data?.surplus
                        ? css.red
                        : p.data && deleted.includes(p.data.id)
                            ? css.deleted
                            : p.data?.user.name === currentUser?.username && css.green
                    }
                />
            </div>
            {sendMoneyRequest && (
                <Dialog
                    header="Отправка денег"
                    open={!!sendMoneyRequest}
                    onClose={() => setSendMoneyRequest(undefined)}
                >
                    <SendMoney
                        request={sendMoneyRequest}
                        onClose={() => setSendMoneyRequest(undefined)}
                    />
                </Dialog>
            )}
        </>
    );
}

export default WithdrawalRequests;
