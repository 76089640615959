import React, { FC } from 'react';

import { Button } from '../Button';

import css from "./pagination.module.scss";

type PaginationProps = {
    page: number;
    setPage: (page: number) => void;
    loading?: boolean;
    hasNextPage?: boolean;
};

const Pagination: FC<PaginationProps> = ({
    page,
    setPage,
    loading = false,
    hasNextPage = true,
}) => (
    <div className={css.pagination}>
        {page !== 1 && (
            <Button
                label="&larr; Назад"
                view="text"
                onClick={() => setPage(page - 1)}
                disabled={loading}
            />
        )}
        {hasNextPage && (
            <Button
                label="Вперёд &rarr;"
                view="text"
                onClick={() => setPage(page + 1)}
                disabled={loading}
            />
        )}
    </div>
);

export default Pagination;
