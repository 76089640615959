import React, { FC, useMemo } from 'react';
import { ColDef } from 'ag-grid-community';

import { EColors } from '@funfarm/kit';
import DataGrid from '@funfarm/kit/DataGrid/DataGrid';

import { FinanceTransaction } from 'types/finances';
import { FilterPrototype } from 'types/table';
import { getTransactions } from 'actions/finances';

import DrawerLayout from 'components/Finances/DrawerLayout';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

const TransactionsHistory: FC = () => {
    const { metrics } = useBalancesAndBrm();

    const columnDefs = useMemo<ColDef<FinanceTransaction>[]>(() => ([
        { field: "date", headerName: "Дата", type: "date" },
        { field: "fromname", headerName: "Отправитель" },
        { field: "toname", headerName: "Получатель" },
        { field: "amount", headerName: "Сумма", type: "highlightAmount" },
        { field: "room", headerName: "Счёт", type: "accountLogo" },
        { field: "type", headerName: "Тип" },
        { field: "modcom", headerName: "Статус", type: "badge", refData: statusColors },
    ]), []);

    return (
        <DrawerLayout
            pageHeadingProps={{
                title: "История переводов",
            }}
            metricsProps={{
                caption: "Денег проекта у игрока",
                value: metrics?.moneyproject || "-",
                currency: "USD"
            }}
        >
            <DataGrid<FinanceTransaction, FilterPrototype>
                fetchData={getTransactions}
                fetchKey="finances/transactions"
                columns={columnDefs}
            />
        </DrawerLayout>
    );
};

const statusColors: Record<string, keyof typeof EColors> = {
    "Проведено": "green",
    "Удален": "red",
};

export default TransactionsHistory;
