import { useState } from 'react';

export const DEFAULT_PAGE_SIZE = 20;

const usePagination = (

) => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

    return {
        page,
        pageSize,
        setPage,
        setPageSize,
    };
}

export default usePagination;
