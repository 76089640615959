import React, { ReactElement } from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { useQuery } from '@tanstack/react-query';

import { Select } from '@funfarm/kit';

import { getCalculationConditions } from 'actions/finances';

type NextPackageProps = {
    field: ControllerRenderProps;
};

const NextPackage = ({ field }: NextPackageProps): ReactElement => {
    const { data } = useQuery({
        queryKey: ["finances", "calculation-conditions"],
        queryFn: () => getCalculationConditions(),
    });

    return (
        <div>
            <Select
                {...field}
                label="Cледующий пакет"
                required
                options={data?.conditions.map((item) => ({
                    label: `${item.percbuyin}%, ${item.percprofit}%`,
                    value: item.id,
                })) || []}
            />
        </div>
    );
};

export default NextPackage;
