import React, { forwardRef,useState } from "react";

import { Input, InputProps } from './index';

export type NumberInputProps = InputProps & {
    min?: number;
    max?: number;
    precision?: number | null;
    value?: string;
    onChange?: (value: number | null) => void;
    allowNegatives?: boolean;
};

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    (
        {
            min = -Infinity,
            max = Infinity,
            precision = null,
            value: initialValue = "",
            onChange,
            allowNegatives = false,
            ...otherProps
        }, ref) => {
        const [value, setValue] = useState<string>(initialValue);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;

            // Allow negative sign only at the beginning if enabled
            if (allowNegatives) {
                inputValue = inputValue.replace(/[^0-9.,-]/g, "");
                if (inputValue.includes("-") && inputValue.indexOf("-") !== 0) {
                    return;
                }
            } else {
                inputValue = inputValue.replace(/[^0-9.,]/g, "");
            }

            // Replace comma with a dot for consistency
            const sanitizedValue = inputValue.replace(/,/g, ".");

            // Prevent multiple dots or commas
            const parts = sanitizedValue.split(".");
            if (parts.length > 2) return;

            // Enforce precision during typing
            if (precision !== null && parts.length === 2 && parts[1].length > precision) {
                return;
            }

            let numericValue = parseFloat(sanitizedValue);

            // Apply min and max constraints
            if (!isNaN(numericValue)) {
                if (numericValue < min) numericValue = min;
                if (numericValue > max) numericValue = max;

                // Apply precision if specified
                if (precision !== null) {
                    numericValue = parseFloat(numericValue.toFixed(precision));
                }
            }

            setValue(inputValue);

            if (onChange) {
                onChange(!isNaN(numericValue) ? numericValue : null);
            }
        };

        const handleBlur = () => {
            if (value === "" || value === "-") return;
            let numericValue = parseFloat(value.replace(",", "."));
            if (numericValue < min) numericValue = min;
            if (numericValue > max) numericValue = max;

            if (precision !== null) {
                numericValue = parseFloat(numericValue.toFixed(precision));
            }

            const formattedValue = numericValue.toString();
            setValue(formattedValue);

            if (onChange) {
                onChange(numericValue);
            }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"];
            const isCmdA = (e.key === "a" || e.key === "A") && (e.metaKey || e.ctrlKey);

            if (
                allowedKeys.includes(e.key) ||
                isCmdA ||
                (e.key === "-" && allowNegatives && value === "") ||
                (e.key === "." && !value.includes(".")) ||
                (e.key === "," && !value.includes(",")) ||
                (!isNaN(Number(e.key)) && e.key !== " ")
            ) {
                return;
            }
            e.preventDefault();
        };

        return (
            <Input
                {...otherProps}
                type="text"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                ref={ref}
            />
        );
    }
);

export default NumberInput;
