import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef, MouseEventHandler, ReactElement } from 'react';
import classNames from 'classnames';

import { IconSpinner } from '@funfarm/kit/Icon';
import { EColors, ESizes, EVariants } from '@funfarm/kit/types';

import css from './button.module.scss';


export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string,
    color?: keyof typeof EColors,
    view?: keyof typeof EVariants,
    size?: keyof typeof ESizes,
    disabled?: boolean,
    loading?: boolean,
    icon?: ReactElement,
    className?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    iconLeft?: ReactElement,
    iconRight?: ReactElement,
    dataTestId?: string,
}


export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
        type = 'button', color = EColors.default, view = EVariants.contained, size = ESizes.medium,
        label, disabled, loading, icon,
        onClick,
        iconLeft, iconRight,
        className, style,
        dataTestId, ...rest
    } = props;


    return (
        <button
            type={type}
            className={classNames(
                className,
                css[color],
                css[view],
                css[size],
                (icon && css.iconButton),
                (loading && css.loading),
                ((iconLeft || iconRight) && css.withIcon),
            )}
            disabled={disabled}
            onClick={loading ? undefined : onClick}
            style={style}
            data-testid={dataTestId}
            ref={ref}
            {...rest}>
            {iconLeft && React.cloneElement(iconLeft, { className: classNames(iconLeft.props.className, css.iconLeft) })}
            {
                loading ?
                    <IconSpinner className={classNames('spin', css.iconLoading)} /> :
                    label ||
                    (icon && React.cloneElement(icon, { size, className: classNames(icon.props.className, css.icon) })) ||
                    props.children
            }
            {iconRight && React.cloneElement(iconRight, { className: classNames(iconRight.props.className, css.iconRight) })}
        </button>
    );
});
