import { FC } from 'react';

import { Button,  Row,  Switcher } from '@funfarm/kit';

import { Currency } from 'types/finances';

import css from '../finances.module.scss';

interface ChildComponentProps {
    allCurrencies: Currency[],
    currentCurrencies: string[],
    defaultCurrencies: string[]
    handleCurrencies: React.Dispatch<React.SetStateAction<string[]>>;
}

const CrossRatesSettings: FC<ChildComponentProps> = ({
    allCurrencies,
    currentCurrencies,
    handleCurrencies,
    defaultCurrencies
}) =>{
    return(
        <>
            <>
                <div className={css.crossrateLine}></div>
                {allCurrencies && (
                    <div>
                        {allCurrencies
                            .filter(currencyItem => currencyItem.symbol !=="¤" && currencyItem.symbol !== "=")
                            .map(item => {
                                const isIncluded = currentCurrencies.includes(item.abrname)
                                return (
                                    <Row 
                                        className={css.crossrateItem}    
                                        spaceBetween
                                        align="center"
                                        key={item.id}
                                        gap={2}>  
                                        <div>{item.name}, {item.symbol}</div>
                                        <Switcher 
                                            value={item.name}
                                            checked={isIncluded}
                                            readOnly={isIncluded}
                                            onChange={() => isIncluded ? 
                                                handleCurrencies(currentCurrencies.filter(i => i !== item.abrname)) :
                                                handleCurrencies([...currentCurrencies, item.abrname])    
                                            }
                                        />
                                    </Row>
                                )
                            })}
                    </div>
                )}
            </>
            <div className={css.crossrateLine}></div>
            <Row className={css.crossrateBtn} align='center' spaceBetween >
                <Button color='secondary'  view="text" onClick={() => handleCurrencies([...defaultCurrencies])}>Настроить по умолчанию</Button>
            </Row> 
        </>
    )
}

export default CrossRatesSettings;