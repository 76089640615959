import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { Error, ISelectProps, MultiSelect, Select, Skeleton } from '@funfarm/kit';

import { money, symbolToCurrencyCode } from 'helpers/numbers';
import { UsedRoomsResponse } from 'types/finances';
import { getUsedRooms } from 'actions/finances';

import useBalancesAndBrm from '../../hooks/useBalancesAndBrm';

import css from "./select.module.scss";

type AccountSelectProps = Partial<ISelectProps> & {
    control: any,
    multiChoice?: boolean,
};

/** @todo move to another folder */
const AccountSelect: FC<AccountSelectProps> = ({
    control,
    multiChoice = false,
    ...otherProps
}) => {
    const { data, isLoading, isError } = useQuery<UsedRoomsResponse>({
        queryKey: ["rooms", "used"],
        queryFn: () => getUsedRooms()
    });
    const { getRoomDetails } = useBalancesAndBrm();
    const options = useMemo(
        () => data?.rooms
            .filter(room => room.allowtransfer)
            .map((room) => {
                const roomDetails = getRoomDetails(room.id);
                const balance = roomDetails && money(
                    roomDetails?.balance_curr as number,
                    symbolToCurrencyCode(roomDetails?.currency_symbol as string)
                );
                return {
                    label: !balance ? room.title : `${room.title}  (${balance})`,
                    value: room.id,
                };
            }) || [],
        [getRoomDetails, data?.rooms]
    );

    const Element = multiChoice ? MultiSelect : Select;

    return isLoading ? (
        <Skeleton className={css.accountSelectBox} />
    ) : (isError || !data?.rooms) ? (
        <Error message="Ошибка получения данных" />
    ) : (
        <Controller
            control={control}
            name="account"
            render={({ field }) => (
                <Element
                    {...field}
                    label="Выбор счёта"
                    required
                    options={options}
                    {...(multiChoice && {
                        multiChoice: true,
                        maxBadges: 3,
                    })}
                    {...otherProps}
                />
            )}
        />
    );
};

export default AccountSelect;
