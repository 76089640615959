import React, { useContext, useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { Checkbox, IError, Input, InputDate, MultiSelect } from '@funfarm/kit';

import { useOptions } from 'helpers';
import { getRooms, getTourneyTypes } from 'actions';

import { TourContext } from 'components/Tournaments/TourProvider';

import css from '../tournaments.module.scss'


export const FilterInputs = () => {
    const { t } = useTranslation();
    const { filterParams, setFilterParams } = useContext(TourContext);

    const { data: roomsData, isLoading: isRoomsLoading } = useQuery<any, IError>({
        queryKey: ['rooms'],
        queryFn: () => getRooms()
    });

    const roomsOptions = useOptions(roomsData, 'id', 'title')

    const { data: tourneyTypesData, isLoading: isTourneyTypesLoading } = useQuery<any, IError>({
        queryKey: ['tourney-types'],
        queryFn: () => getTourneyTypes()
    });
    const tourneyTypesOptions = useOptions(tourneyTypesData, 'value', 'label')

    const seriesData = [
        {label: "Regular", value: "false"},
        {label: "Series", value: "true"},
    ]
    const seriesOptions = useOptions(seriesData, 'value', 'label')
    
    const{
        control,
        reset
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: filterParams
    });

    const watchedFields = useWatch({ control });
    const previousFieldsRef = useRef(watchedFields);

    useEffect(() => {
        if(JSON.stringify(previousFieldsRef.current) !== JSON.stringify(watchedFields)) {
            setFilterParams && setFilterParams(watchedFields);
            previousFieldsRef.current = watchedFields;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedFields]);


    useEffect(() => {
        reset(filterParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);
    return (
        <div className={css.form}>
            <Controller
                control={control}
                name="rooms_id"
                render={({field}) => (
                    <MultiSelect
                        {...field}
                        className={css.form__input}
                        maxBadges={3}
                        options={roomsOptions}
                        label={t('Rooms')}
                        labelPosition="inside"
                        loading={isRoomsLoading}
                        checkboxes
                    />
                )}/>

            <Controller
                control={control}
                name="date_start"
                render={({field}) => (
                    <InputDate
                        {...field}
                        showTimeInput
                        timeInputLabel={t('Time')}
                        dateFormat = 'dd.MM.yyyy HH:mm'
                        // minDate={new Date()}
                        labelPosition="inside"
                    />
                )}/>

            <Controller
                control={control}
                name="date_end"
                render={({field}) => (
                    <InputDate
                        {...field}
                        showTimeInput
                        timeInputLabel={t('Time')}
                        dateFormat = 'dd.MM.yyyy HH:mm'
                        // minDate={new Date()}
                        labelPosition="inside"
                    />
                )}/>

            <Controller
                control={control}
                name="tourney_type"
                render={({field}) => (
                    <MultiSelect
                        {...field}
                        maxBadges={3}
                        options={tourneyTypesOptions.map((item) => ({
                            ...item,
                            label: t("Tourney " + item.label),
                        }))}
                        loading={isTourneyTypesLoading}
                        labelPosition="inside"
                        label={t('Tourney type')}
                        checkboxes
                    />
                )}/>

            <Controller
                control={control}
                name="is_series"
                render={({field}) => (
                    <MultiSelect
                        {...field}
                        options={seriesOptions}
                        labelPosition="inside"
                        label={t('Regular/Series')}
                        checkboxes
                    />
                )}/>

            <Controller
                control={control}
                name="bi_start"
                render={({field}) => (
                    <Input
                        {...field}
                        label={t('Min Buy-in')}
                        labelPosition="left"
                        style={{ marginBottom: 0 }}
                    />
                )}/>

            <Controller
                control={control}
                name="show_hidden"
                render={({ field }) => (
                    <Checkbox
                        {...field}
                        label={t('Hidden')}
                        labelPosition="right"
                        value="1"
                        checked={!!field.value}
                    />
                )}/>

            <Controller
                control={control}
                name="show_passed"
                render={({ field }) => (
                    <Checkbox
                        {...field}
                        label={t('Past')}
                        labelPosition="right"
                        value="1"
                        checked={!!field.value}
                    />
                )}/>
        </div>
    );
};
