import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import classNames from 'classnames';
import { differenceInMinutes, isAfter, isBefore, parseISO } from 'date-fns';

import {
    Button,
    IconHyperTurbo,
    IconKnockout,
    IconSix,
    IconStairs,
    IconTurbo,
    IError, Table,
    TableCell,
    TableContext,
    TableRow,
    Toast
} from '@funfarm/kit';

import { displayDate, displayStartDate } from 'helpers';
import { money } from 'helpers/numbers';
import { ITournament } from 'types';
import { postWillNotPlay, postWillPlay } from 'actions';

import { TourContext } from 'components/Tournaments/TourProvider';

import css from './table.module.scss';


interface IProps {
    tournament: ITournament,
    selected?: boolean
}

export const TournamentRow = (props: IProps) => {
    const { tournament, selected } = props;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { rowsChecked } = useContext(TableContext);
    const { filterParams } = useContext(TourContext);

    const [submitted, setSubmitted] = useState<boolean>(false);


    const handleWillPlay = (row: Record<string, any>) => {
        setSubmitted(true);

        return postWillPlay([row])
            .then(() => {
                queryClient.invalidateQueries({ queryKey: ['schedule'] });
            })
            .catch((error: IError) => {
                console.error(error);

                Toast.error({ message: error.message });

                return false;
            })
            .finally(() => setSubmitted(false));
    };


    const handleWillNotPlay = (row: Record<string, any>) => {
        setSubmitted(true);

        return postWillNotPlay([row])
            .then(() => {
                queryClient.invalidateQueries({ queryKey: ['schedule'] });
            })
            .catch((error: IError) => {
                console.error(error);

                Toast.error({ message: error.message });

                return false;
            })
            .finally(() => setSubmitted(false));
    };


    const getColor = useCallback((startDate: string | null, endDate?: string | null) => {
        if(!startDate)
            return null;

        const now = new Date();
        const start = parseISO(startDate ?? '');
        const end = parseISO(endDate ?? '');

        if(isBefore(now, startDate)) {
            const minutesToStart = differenceInMinutes(start, now);

            if(minutesToStart < 60)
                return 'green';
        }

        if(isAfter(now, end))
            return 'grey';

        if(isAfter(now, start))
            return 'red';

        return null;
    }, []);


    const renderIconType = useCallback((tournamentType: number) => {
        switch (tournamentType) {
            case 1:
                return (
                    <>
                        <IconKnockout />
                    </>
                );
            case 2:
                return (
                    <>
                        {/*снежинка*/}
                    </>
                );
            case 3:
                return (
                    <>
                        {/*снежинка*/}
                        <IconSix />
                    </>
                );
            case 4:
                return (
                    <>
                        <IconKnockout />
                        <IconTurbo />
                        <IconSix />
                    </>
                );
            case 5:
                return (
                    <>
                        <IconKnockout />
                        <IconHyperTurbo />
                        <IconSix />
                    </>
                );
            case 6:
                return (
                    <>
                        <IconKnockout />
                        <IconTurbo />
                        {/*9*/}
                    </>
                );
            case 7:
                return (
                    <>
                        <IconTurbo />
                        {/*снежинка*/}
                        <IconSix />
                    </>
                );
            case 8:
                return (
                    <>
                        <IconTurbo />
                        {/*снежинка*/}
                        {/*9*/}
                    </>
                );
            case 9:
                return (
                    <>
                        <IconHyperTurbo />
                        {/*снежинка*/}
                        {/*9*/}
                    </>
                );
            case 10:
                return (
                    <>
                        <IconStairs />
                    </>
                );
            default:
                return null;
        }
    }, []);


    return (
        <TableRow
            className={cn(css.tournamentsRow, !selected && css[`gradient-${tournament.color}`])}
            key={tournament.name}
            checked={!!rowsChecked?.find(r => r.id === tournament.id.toString())}
        >
            <span className={cn(css.color, css[`color-${tournament.color}`])} />
            <Table.Cell actions={1}>
                {
                    !selected &&
                    <Table.ActionCheckbox rowId={tournament.id} rowData={{ id: tournament.id.toString(), color: tournament.color }} />
                }
            </Table.Cell>
            <TableCell colspan={2}>{tournament.room}</TableCell>
            <TableCell colspan={5} nowrap>{tournament.name}</TableCell>
            <TableCell  colspan={4} style={{paddingLeft: "3rem"}} className={css.startDate}>
                <span>{displayDate(tournament.start_date)}</span>
                <span className={css[`date-${getColor(tournament.start_date, tournament.end_date)}`]}>
                    {displayStartDate(tournament.start_date, tournament.end_date)}
                </span>
            </TableCell>
            <TableCell colspan={2}>
                {money(Number(tournament.bi), tournament.currency)}
            </TableCell>
            <TableCell colspan={2} id="rank">{tournament.rank}</TableCell>
            <TableCell colspan={3} id="end_date">{displayDate(tournament.end_date)}</TableCell>
            <TableCell colspan={1} id="type">
                {renderIconType(tournament.type)}
            </TableCell>
            <TableCell colspan={2} id="vinrate">{tournament.winrate}</TableCell>
            <TableCell colspan={2} id="fishes">{tournament.fishes}</TableCell>
            <TableCell colspan={2} id="tournament">{tournament.tournament}</TableCell>
            <TableCell colspan={3} align="right">
                {
                    selected ?
                        <Button
                            color="red"
                            view="outlined"
                            size="small"
                            className={css.button}
                            onClick={() => handleWillNotPlay({ id: tournament.id })}
                        >
                            {t('Delete')}
                        </Button> :
                        !filterParams?.show_hidden &&
                        <Button
                            color="secondary"
                            size="small"
                            className={classNames(css.button, submitted && css.blur)}
                            onClick={() => handleWillPlay({ id: tournament.id, color: tournament.color })}
                        >
                            {t('Will play')}
                        </Button>
                }
            </TableCell>
        </TableRow>
    );
};
