import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';

import TableRenderer from '@funfarm/kit/Table/TableRenderer';

import { UsedRoomsResponse } from 'types/finances';
import { getBalancesHistory, getUsedRooms } from 'actions/finances';

import prepareBalanceHistoryColumns from 'components/Finances/BalanceHistory/balanceHistory.columns';

import BalanceHistoryFilter from './BalanceHistoryFilter';

import css from '../finances.module.scss';

const BalanceHistory: FC = () => {
    const { data } = useQuery<UsedRoomsResponse>({
        queryKey: ["rooms", "used"],
        queryFn: () => getUsedRooms()
    });
    return (
        <>
            <BalanceHistoryFilter/>
            <TableRenderer
                fetchKey="finances/balances/history"
                fetchData={getBalancesHistory}
                columns={data?.rooms && prepareBalanceHistoryColumns(
                    data.rooms.map((item) => ({
                        id: item.id,
                        title: item.title
                    }))
                )}
                classNames={{
                    table: css.balanceHistoryTable,
                    header: css.balanceHistoryHeader,
                    row: css.balanceHistoryRow,
                }}
            />
        </>
    );
};

export default BalanceHistory;
