import React, { createContext, useContext, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import Slider from "rc-slider";

import { Checkbox, IError, InputDate, MultiSelect, Select } from "@funfarm/kit";

import { useOptions } from "helpers/useOptions";
import { ITrainingPlayer, ITrainingReportForm, ITrainingReportPlayer } from "types/index";
import { createReport, getAllPlayers, getGroups, getPlayersInGroup, getSubjects } from "actions/trainings";

import "rc-slider/assets/index.css";
import css from "./training_reporst.module.scss";

export interface IProviderProps {
  params: ITrainingReportForm,
  setParams: (params: ITrainingReportForm) => void,
}

const TRAINING_TYPES = [
    { value: "basic", label: "Общая" },
    { value: "individual", label: "Индивидуалка" },
    { value: "trigger", label: "Триггерная" },
    { value: "consultation", label: "Консультация" },
    { value: "curation", label: "Кураторство" }
];

export const defaultParams: ITrainingReportForm = {
    training_date: new Date().getTime(),
    training_type: "",
    group_id: "",
    player_ids: [], // изменено на массив
    subjects: [],
    was_base_checked: false,
    has_homework: false,
    homework_subjects: [],
    player_feedback: []
};

const defaultContextValue: IProviderProps = {
    params: defaultParams,
    setParams: () => {
    }
};

const ReportContext = createContext<IProviderProps>(defaultContextValue);

export const TrainingReports = () => {
    const { params, setParams } = useContext(ReportContext);

    const {
        control,
        reset,
        handleSubmit,
        setValue
    } = useForm({
        defaultValues: params,
        mode: "onChange",
        reValidateMode: "onChange"
    });

    const watchedFields = useWatch({ control });

    const { data: subjectsData, isLoading: isSubjectsLoading } = useQuery<any, IError>({
        queryKey: ["subjects"],
        queryFn: () => getSubjects()
    });
    const subjectsOptions = useOptions(subjectsData, "id", "name");

    const { data: groupsData, isLoading: isGroupsLoading } = useQuery<any, IError>({
        queryKey: ["groups"],
        queryFn: () => getGroups()
    });
    const groupOptions = useOptions(groupsData, "id", "name");

    const { data: groupPlayerData } = useQuery<ITrainingPlayer[], IError>({
        queryKey: ["players", watchedFields.group_id],
        queryFn: async () => {
            if (watchedFields.group_id) {
                return getPlayersInGroup(watchedFields.group_id);
            }
            return [];
        }
    });

    const { data: playersData, isLoading: isPlayersLoading } = useQuery<ITrainingPlayer[], IError>({
        queryKey: ["players", "all"],
        queryFn: async (): Promise<ITrainingPlayer[]> => {
            return await getAllPlayers();
        }
    });

    const playerOptions = useOptions(playersData, "id", "username");

    const [feedbacks, setFeedbacks] = useState<ITrainingReportPlayer[]>(params.player_feedback || []);

    useEffect(() => {
        setParams({
            ...params,
            player_feedback: feedbacks
        });
    }, [feedbacks, setParams]);

    // Сброс связанных полей при изменении типа тренировки
    useEffect(() => {
        reset({
            ...watchedFields,
            player_ids: [],
            group_id: ""
        });
        setFeedbacks([]);
    }, [watchedFields.training_type, reset]);

    useEffect(() => {
        if (watchedFields.group_id && groupPlayerData) {
            setValue("player_ids", groupPlayerData.map(player => player.id));
        }
    }, [watchedFields.group_id, groupPlayerData]);

    // Обновление feedback при выборе игроков
    useEffect(() => {
        const ids = watchedFields.player_ids || [];
        const remainFeedbacks = feedbacks.filter(f => ids.includes(f.player_id));

        if (playersData || groupPlayerData) {
            const existedFeedbacks = remainFeedbacks.map(f => f.player_id);
            const newPlayersID = ids.filter(id => !existedFeedbacks.includes(id));
            const playerData = (playersData || groupPlayerData) as ITrainingPlayer[];
            const selectedPlayers = playerData.filter(p => newPlayersID.includes(p.id));

            const newFeedbacks = selectedPlayers.map(player => ({
                player_id: player.id,
                username: player.username,
                was_visited: false,
                was_homework_done: false,
                activity_score: 0,
                skill_score: 0,
                cant_define: true
            }));
            setFeedbacks([...remainFeedbacks, ...newFeedbacks]);
        }
    }, [watchedFields.player_ids]);

    // Обработчик отправки формы
    const onSubmit = (data: ITrainingReportForm) => {
        const processedData = {
            ...data,
            training_date: format(new Date(data.training_date), "yyyy-MM-dd"),
            player_feedback: feedbacks
        };
        createReport(processedData).then(() => {
            alert("Отчёт отправлен успешно!");
        }).catch((error) => {
            alert(`Ошибка при отправке отчёта: ${error.message}`);
        });
    };

    // Обработчики обновления feedbacks
    const handlePlayerSelected = (player_id: string) => () => {
        const idx = feedbacks.findIndex(f => f.player_id === player_id);
        if (idx === -1) return;
        const updated = [...feedbacks];
        updated[idx] = {
            ...updated[idx],
            was_visited: !updated[idx].was_visited
        };
        setFeedbacks(updated);
    };

    const handlePlayerActivityChanged = (player_id: string, value: number) => {
        const idx = feedbacks.findIndex(f => f.player_id === player_id);
        if (idx === -1) return;

        const updated = [...feedbacks];
        updated[idx] = {
            ...updated[idx],
            activity_score: value,
            cant_define: value === 0
        };
        setFeedbacks(updated);
    };

    const handlePlayerSkillSelected = (player_id: string, value: number) => {
        const idx = feedbacks.findIndex(f => f.player_id === player_id);
        if (idx === -1) return;

        const updated = [...feedbacks];
        updated[idx] = {
            ...updated[idx],
            skill_score: value,
            cant_define: value === 0
        };
        setFeedbacks(updated);
    };

    const handlePlayerCantEstimate = (player_id: string) => () => {
        const idx = feedbacks.findIndex(f => f.player_id === player_id);
        if (idx === -1) return;

        const updated = [...feedbacks];
        updated[idx] = {
            ...updated[idx],
            activity_score: 0,
            skill_score: 0,
            cant_define: !updated[idx].cant_define
        };
        setFeedbacks(updated);
    };

    return (
        <div className={css.pageGrid}>
            <div className={css.title}>Отчётность по тренировке</div>
            <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={css.formItem}>
                    <label>Дата</label>
                    <Controller
                        control={control}
                        name="training_date"
                        render={({ field }) => (
                            <InputDate
                                {...field}
                                timeInputLabel={"Дата тренировки"}
                                dateFormat="dd.MM.yyyy"
                                labelPosition="inside"
                            />
                        )} />
                </div>
                <div className={css.formItem}>
                    <label>Тип тренировки</label>
                    <Controller
                        control={control}
                        name="training_type"
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={TRAINING_TYPES}
                                label={"Выберите тип тренировки"}
                                labelPosition="inside"
                                loading={false}
                                className={css.formControl}
                            />
                        )} />
                </div>

                {watchedFields.training_type === "basic" ? (
                    <div className={css.formItem}>
                        <label>Выбрать группу</label>
                        <Controller
                            control={control}
                            name="group_id"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={groupOptions}
                                    label={"Выберите группу"}
                                    labelPosition="inside"
                                    loading={isGroupsLoading}
                                    className={css.formControl}
                                />
                            )} />
                    </div>
                ) : watchedFields.training_type === "trigger" ? (
                    <div className={css.formItem}>
                        <label>Выбрать игроков</label>
                        <Controller
                            control={control}
                            name="player_ids"
                            render={({ field }) => (
                                <MultiSelect
                                    {...field}
                                    options={playerOptions}
                                    label={"Выберите игроков"}
                                    labelPosition="inside"
                                    loading={isPlayersLoading}
                                    checkboxes
                                    className={css.formControl}
                                    maxBadges={2}
                                    onChange={(selectedOptions) => {
                                        field.onChange(selectedOptions.target.value.split(","));
                                    }}
                                />
                            )} />
                    </div>
                ) : (
                    <div className={css.formItem}>
                        <label>Выбрать игроков</label>
                        <Controller
                            control={control}
                            name="player_ids"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={playerOptions}
                                    label={"Выберите игрока"}
                                    labelPosition="inside"
                                    isMulti
                                    loading={isPlayersLoading}
                                    className={css.formControl}
                                    onChange={(selectedOptions) => {
                                        field.onChange([selectedOptions.target.value]);
                                    }}
                                />
                            )} />
                    </div>
                )}

                <div className={css.formItem}>
                    <label>Тема тренировки</label>
                    <Controller
                        control={control}
                        name="subjects"
                        render={({ field }) => (
                            <MultiSelect
                                {...field}
                                options={subjectsOptions}
                                label={"Выберите темы тренировки"}
                                labelPosition="inside"
                                loading={isSubjectsLoading}
                                className={css.formControl}
                                checkboxes
                                onChange={(selectedOptions) => {
                                    field.onChange(selectedOptions.target.value.split(",") || []);
                                }}
                            />
                        )} />
                </div>
                <div className={css.formItem}>
                    <label>Проверялась ли база</label>
                    <Controller
                        control={control}
                        name="was_base_checked"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                label={"Проверялась ли база"}
                                labelPosition="right"
                                checked={!!field.value}
                                onChange={e => field.onChange(e.target.checked)}
                            />
                        )} />
                </div>
                <div className={css.formItem}>
                    <label>Задавалось ли ДЗ</label>
                    <Controller
                        control={control}
                        name="has_homework"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                label={"Задавалось ли ДЗ"}
                                labelPosition="right"
                                checked={!!field.value}
                                onChange={e => field.onChange(e.target.checked)}
                            />
                        )} />
                </div>

                {watchedFields.has_homework ?
                    <div className={css.formItem}>
                        <label>Выберите темы ДЗ</label>
                        <Controller
                            control={control}
                            name="homework_subjects"
                            render={({ field }) => (
                                <MultiSelect
                                    {...field}
                                    options={subjectsOptions}
                                    label={"Выберите типы ДЗ"}
                                    labelPosition="inside"
                                    loading={false}
                                    onChange={(selectedOptions) => {
                                        field.onChange(selectedOptions.target.value.split(",") || []);
                                    }}
                                />
                            )} />
                    </div> :
                    <div></div>
                }

                {feedbacks.length > 0 ?
                    <div>
                        <label>Явка и отзывы</label>
                        <div className={css.reportForm}>
                            <div className={css.header}>
                                <div className={css.cell}>Посещаемость</div>
                                <div className={css.cell}>Активность</div>
                                <div className={css.cell}>Скиллы</div>
                                <div className={css.cell}>Не удалось определить</div>
                            </div>
                            <div className={css.row}>
                                <div className={css.cell}>
                                    <label className={css.name}>
                    Были все
                                        <input
                                            type="checkbox"
                                            checked={feedbacks.every(f => f.was_visited)}
                                            onChange={() => {
                                                const allVisited = feedbacks.every(f => f.was_visited);
                                                const updated = feedbacks.map(f => ({
                                                    ...f,
                                                    was_visited: !allVisited
                                                }));
                                                setFeedbacks(updated);
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className={css.cell}>
                                    <div className={css.legendGrid}>
                                        <div className={css.legendItem}>Крайне пассивный</div>
                                        <div className={css.legendItem}>Пассивный</div>
                                        <div className={css.legendItem}>Не удалось определить</div>
                                        <div className={css.legendItem}>Активный</div>
                                        <div className={css.legendItem}>Очень активный</div>
                                    </div>
                                </div>
                                <div className={css.cell}>
                                    <div className={css.legendGrid}>
                                        <div className={css.legendItem}>Очень слабые</div>
                                        <div className={css.legendItem}>Слабые скиллы</div>
                                        <div className={css.legendItem}>Не удалось определить</div>
                                        <div className={css.legendItem}>Средние скиллы</div>
                                        <div className={css.legendItem}>Высокие скиллы</div>
                                    </div>
                                </div>
                                <div className={css.cell}>
                                    <input
                                        type="checkbox"
                                        checked={feedbacks.every(f => f.cant_define)}
                                        onChange={() => {
                                            const allCantDefine = feedbacks.every(f => f.cant_define);
                                            const updated = feedbacks.map(f => ({
                                                ...f,
                                                cant_define: !allCantDefine,
                                                activity_score: !allCantDefine ? 0 : f.activity_score,
                                                skill_score: !allCantDefine ? 0 : f.skill_score
                                            }));
                                            setFeedbacks(updated);
                                        }}
                                    />
                                </div>
                            </div>
                            {feedbacks.map((feedback: ITrainingReportPlayer) => (
                                <div className={css.row} key={feedback.player_id}>
                                    <div className={css.cell}>
                                        <label className={css.name}>{feedback.username}
                                            <input
                                                type="checkbox"
                                                name={`was_visited[${feedback.player_id}]`}
                                                checked={feedback.was_visited}
                                                onChange={handlePlayerSelected(feedback.player_id)}
                                            />
                                        </label>
                                    </div>
                                    <div className={css.cell}>
                                        <div className={css.slider}>
                                            <Slider
                                                disabled={!feedback.was_visited}
                                                className={!feedback.was_visited ? css.rcSliderDisabled : ""}
                                                min={-2}
                                                max={2}
                                                reverse={false}
                                                startPoint={0}
                                                value={feedback.activity_score}
                                                onChange={(e) => handlePlayerActivityChanged(feedback.player_id, Number(e))}
                                                styles={{
                                                    track: { backgroundColor: feedback.activity_score > 0 ? "#4CAF50" : "#af4c4c", height: "8px" },
                                                    rail: { backgroundColor: "#989898", height: "8px" },
                                                    handle: { backgroundColor: "#ACAFB5", height: "16px", width: "16px" }
                                                }}
                                                dots={true}
                                                dotStyle={{ height: "16px", width: "16px", marginBottom: "-5px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className={css.cell}>
                                        <div className={css.slider}>
                                            <Slider
                                                disabled={!feedback.was_visited}
                                                className={!feedback.was_visited ? css.rcSliderDisabled : ""}
                                                min={-2}
                                                max={2}
                                                startPoint={0}
                                                reverse={false}
                                                height={10}
                                                value={feedback.skill_score}
                                                onChange={(e) => handlePlayerSkillSelected(feedback.player_id, Number(e))}
                                                styles={{
                                                    track: { backgroundColor: feedback.skill_score > 0 ? "#4CAF50" : "#af4c4c", height: "8px" },
                                                    rail: { backgroundColor: "#989898", height: "8px" },
                                                    handle: { backgroundColor: "#ACAFB5", height: "16px", width: "16px" }
                                                }}
                                                dots={true}
                                                dotStyle={{ height: "16px", width: "16px", marginBottom: "-5px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className={css.cell}>
                                        <input
                                            type="checkbox"
                                            name={`cant_define[${feedback.player_id}]`}
                                            checked={feedback.cant_define}
                                            onChange={handlePlayerCantEstimate(feedback.player_id)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    : <></>
                }
                <div>
                    <button type="submit">Отправить отчёт</button>
                </div>
            </form>
        </div>
    );
};
