import React, { forwardRef } from 'react';

import NumberInput, { NumberInputProps } from './NumberInput';

import css from './input.module.scss';

export type MoneyInputProps = NumberInputProps & {
    currency: string;
    allowNegatives?: boolean;
};

const  MoneyInput = forwardRef<HTMLInputElement, MoneyInputProps>(
    (
        {
            currency,
            allowNegatives = false,
            ...props
        },
        ref,
    ) => (
        <NumberInput
            {...props}
            precision={2}
            allowNegatives={allowNegatives}
            {...(!allowNegatives && {
                min: 0
            })}
            ref={ref}
            iconRight={
                <span className={css.currencyInner}>
                    {currency}
                </span>
            }
        />
    )
);

export default MoneyInput;
